import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { LEADERBOARDS } from "./apiRoutes";
import moment from "moment";
import "moment/locale/de";
import i18n from "i18n";

const leaderboardsModel = {
  leaderboardsComplete: false,
  leaderboards: [],
  selectDayOptions: [],
  selectStageOptions: [],
  intervalsDays: [],
  intervalsWeeks: [],
  favoriteTeamUserProfile: null,
  isLoading: false,
  offset: 0,

  setLeaderboard: action((state, payload) => {
    const newData = payload.data.map((item, index) => {
      return {
        id: item._id,
        name: item.user?.nickname || item?.teamDetails?.name || "",
        image: item.user?.profilePicture || item?.teamDetails?.emblemUrl,
        points: item?.points || item?.earnedPoints || 0,
        position: item?.position || index + 1,
      };
    });
    if (payload.offset === 0) {
      state.leaderboards = newData;
    } else {
      state.leaderboards = [...state.leaderboards, ...newData];
    }
  }),
  setLeaderboardComplete: action((state, payload) => {
    state.leaderboardsComplete = payload;
  }),
  resetLeaderboard: action((state) => {
    state.offset = 0;
    state.leaderboardsComplete = false;
    state.leaderboards = [];
  }),
  setIntervalsDays: action((state, payload) => {
    state.intervalsDays = payload;
  }),
  setIntervalsWeeks: action((state, payload) => {
    state.intervalsWeeks = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  increaseOffset: action((state, payload) => {
    state.offset += payload;
  }),

  setFavoriteTeamUserProfile: action((state, payload) => {
    if (payload === null) {
      state.favoriteTeamUserProfile = null;
    } else {
      state.favoriteTeamUserProfile = {
        id: payload.id,
        name: payload.name || "No Team Name",
        image: payload.image,
        points: payload.points !== null ? payload.points.toFixed(2) : "-",
        position: payload.position || 1,
      };
    }
  }),

  getSelectData: thunk(async (actions, payload) => {
    const { data } = await api.get(LEADERBOARDS.SELECT_OPTIONS);
    moment.locale(payload);
    const days = data?.intervalsMatchdays?.map((day) => {
      return {
        ...day,
        label: `${payload === "de" ? day?.nameDE : day?.nameEN}: 
            ${
          moment(day.startDate).utc().format("DD") === moment(day.endDate).utc().format("DD")
            ? moment(day.startDate).utc().format("DD MMMM YYYY")
            : moment(day.endDate).utc().format("DD") -
            moment(day.startDate).utc().format("DD") ===
            1
              ? `${moment(day.startDate).utc().format("DD")}/${moment(day.endDate).utc().format("DD")} ${moment(day.startDate).utc().format("MMMM YYYY")}`
              : `${moment(day.startDate).utc().format("DD")}-${moment(day.endDate).utc().format("DD")} ${moment(day.startDate).utc().format("MMMM YYYY")}`
        }
          `,
        value: { ...day },
      };
    });
    const weeks = data?.intervalsWeeks
      ?.map((week, index) => {
        return {
          ...week,
          label: `${i18n.t("WEEK")} ${index + 1} (${moment(week.startDate)
            .utc()
            .format("DD MMM")} - ${moment(week.endDate).utc().format("DD MMM YYYY")})`,
          value: { ...week },
        };
      })
      ?.reverse();
    actions.setIntervalsDays(days);
    actions.setIntervalsWeeks(weeks);
  }),

  getLeaderboardsUsers: thunk(async (actions, payload, { getState }) => {
    actions.setIsLoading(true);
    const isComplete = getState().leaderboardsComplete;
    if (!isComplete) {
      let url = "";
      if (payload.favoriteTeam === true) {
        url += LEADERBOARDS.LEADERBOARDS_USERS + "/teams?challengeId=" + payload.challengeId;
      } else {
        url += LEADERBOARDS.LEADERBOARDS_USERS + "?challengeId=" + payload.challengeId;
      }

      if (payload.type !== undefined) {
        url += "&type=" + payload.type;
      }
      if (payload.startDate !== undefined && payload.endDate !== undefined) {
        const utcStartDate = new Date(payload.startDate);
        const utcStartDateMinus2Hours = new Date(utcStartDate);
        utcStartDateMinus2Hours.setUTCHours(utcStartDateMinus2Hours.getUTCHours() - 2);

        const utcEndDate = new Date(payload.endDate);
        const utcEndDateMinus2Hours = new Date(utcEndDate);
        utcEndDateMinus2Hours.setUTCHours(utcEndDateMinus2Hours.getUTCHours() - 2);

        url +=
          "&startDate=" +
          utcStartDateMinus2Hours.toISOString() +
          "&endDate=" +
          utcEndDateMinus2Hours.toISOString();
      }
      if (payload.offset !== undefined && payload.limit !== undefined) {
        url += "&offset=" + payload.offset + "&limit=" + payload.limit;
      }
      const { data } = await api.get(url);
      if (data?.length === 0 || data?.length < 20) actions.setLeaderboardComplete(true);
      else actions.setLeaderboardComplete(false);
      actions.setLeaderboard({ data, offset: payload.offset });
      actions.setIsLoading(false);
    }
  }),

  getFavoriteTeamUserProfile: thunk(async (actions, payload) => {
    actions.setIsLoading(true);

    let url = LEADERBOARDS.LEADERBOARDS_USERS + "/teams/me?challengeId=" + payload.challengeId;

    if (payload.type === 1) {
      const { startDate, endDate } = payload;
      if (startDate && endDate) {
        const utcStartDate = new Date(startDate);
        const utcEndDate = new Date(endDate);
        utcStartDate.setUTCHours(utcStartDate.getUTCHours() - 2);
        utcEndDate.setUTCHours(utcEndDate.getUTCHours() - 2);

        url += `&type=1&startDate=${utcStartDate.toISOString()}&endDate=${utcEndDate.toISOString()}`;
      }
    } else {
      url += "&type=0";
    }

    try {
      const { data } = await api.get(url);

      if (data && data.length > 0) {
        const favoriteTeamData = data[0];
        const formattedFavoriteTeam = {
          id: favoriteTeamData._id.teamId,
          name: favoriteTeamData.teamDetails.name || "Unknown Team",
          image: favoriteTeamData.teamDetails.emblemUrl || null,
          points: favoriteTeamData.points || 0,
          position: favoriteTeamData.position || 1,
        };

        actions.setFavoriteTeamUserProfile(formattedFavoriteTeam);
      } else {
        actions.setFavoriteTeamUserProfile(null);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        actions.setFavoriteTeamUserProfile(null);
      } else {
        console.error("Error fetching favorite team user profile data:", error);
        actions.setFavoriteTeamUserProfile(null);
      }
    }

    actions.setIsLoading(false);
  }),








  resetLeaderboards: thunk(async (actions) => {
    actions.resetLeaderboard();
  }),
};

export default leaderboardsModel;
