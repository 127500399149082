import React, { useEffect, useState } from "react";
import { LoaderWithLogo } from "components";
import styles from "./Leaderboards.module.scss";
import firstPlaceIcon from "assets/1.svg";
import firstPlaceIconWhite from "assets/1-white.svg";
import secondThirdPlaceIcon from "assets/2-3.svg";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import SelectComponent from "components/SelectComponent/SelectComponent";

const Leaderboards = () => {
  const { t } = useTranslation();
  const {
    leaderboards,
    leaderboardsComplete,
    intervalsWeeks,
    intervalsDays,
    isLoading,
    favoriteTeamUserProfile,
  } = useStoreState((store) => store.leaderboards);
  const { chalangeId } = useStoreState((store) => store.game);
  const {
    getSelectData,
    getLeaderboardsUsers,
    getFavoriteTeamUserProfile,
    setLeaderboardComplete,
  } = useStoreActions((actions) => actions.leaderboards);
  const { nickname, points, rank, pointsSecond, rankSecond } = useStoreState(
    (store) => store.user
  );
  const { lang } = useStoreState((store) => store.user);

  const { getUserStats, getUserSecondStats } = useStoreActions(
    (actions) => actions.user
  );

  const selectPeriodOptions = [
    { value: "all-time", label: t("leaderboards.all_time") },
    { value: "matchday", label: t("leaderboards.matchday") },
  ];

  const [filterType, setFilterType] = useState("overall");
  const [periodFilter, setPeriodFilter] = useState(selectPeriodOptions[0]);
  const [weekFilter, setWeekFilter] = useState(intervalsWeeks?.[0]);
  const [dayFilter, setDayFilter] = useState(intervalsDays?.[0]);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const currentPoints =
    periodFilter.value === "matchday" ? pointsSecond : points;
  const currentRank = periodFilter.value === "matchday" ? rankSecond : rank;

  useEffect(() => {
    getSelectData(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (periodFilter.value === "weekly") {
      setWeekFilter(intervalsWeeks?.[0]);
    }
    if (periodFilter.value === "matchday") {
      setDayFilter(intervalsDays?.[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodFilter]);

  useEffect(() => {
    setOffset(0);
    setLeaderboardComplete(false);
    fetchData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayFilter, weekFilter, periodFilter, chalangeId, filterType]);

  const fetchData = (newOffset) => {
    if (chalangeId) {
      if (filterType === "favoriteTeam") {
        if (periodFilter.value === "all-time") {
          getFavoriteTeamUserProfile({
            challengeId: chalangeId,
            type: 0,
          });
        } else if (periodFilter.value === "matchday" && dayFilter) {
          getFavoriteTeamUserProfile({
            challengeId: chalangeId,
            type: 1,
            startDate: dayFilter.startDate,
            endDate: dayFilter.endDate,
          });
        }
      }

      if (periodFilter.value === "all-time") {
        getLeaderboardsUsers({
          type: 0,
          challengeId: chalangeId,
          offset: newOffset,
          limit: limit,
          leaderboards: leaderboards,
          favoriteTeam: filterType === "favoriteTeam",
        });
        getUserStats(chalangeId);
      } else if (periodFilter.value === "matchday" && dayFilter) {
        getLeaderboardsUsers({
          challengeId: chalangeId,
          type: 1,
          startDate: dayFilter.startDate,
          endDate: dayFilter.endDate,
          offset: newOffset,
          limit: limit,
          leaderboards: leaderboards,
          favoriteTeam: filterType === "favoriteTeam",
        });
        getUserSecondStats({
          chalangeId: chalangeId,
          type: 1,
          startDate: dayFilter.startDate,
          endDate: dayFilter.endDate,
        });
      }
    }
  };

  const fetchdataHandler = () => {
    if (!leaderboardsComplete) {
      fetchData(offset + limit);
      setOffset((prev) => prev + limit);
    }
  };

  const combinedLeaderboards = [...leaderboards];

  return (
    <div className={styles.leaderboards}>
      <div className={classNames(styles.filter_container, "container")}>
        <SelectComponent
          options={selectPeriodOptions}
          value={periodFilter}
          setValue={setPeriodFilter}
          colored={true}
        />
        <div className={styles.filter}>
          {periodFilter.value === "matchday" && intervalsDays.length > 0 && (
            <SelectComponent
              options={intervalsDays}
              setValue={setDayFilter}
              value={dayFilter}
              label={t("Select day")}
              colored={true}
            />
          )}
        </div>
      </div>

      <div className={classNames(styles.buttons, "container")}>
        <button
          className={classNames(styles.button, {
            [styles.active]: filterType === "overall",
          })}
          onClick={() => setFilterType("overall")}
        >
          {t("leaderboards.overall")}
        </button>
        <button
          className={classNames(styles.button, {
            [styles.active]: filterType === "favoriteTeam",
          })}
          onClick={() => setFilterType("favoriteTeam")}
        >
          {t("leaderboards.favorite_team")}
        </button>
      </div>

      <InfiniteScroll
        dataLength={combinedLeaderboards.length}
        next={fetchdataHandler}
        hasMore={!leaderboardsComplete || leaderboards?.length % offset === 0}
        loader={<LoaderWithLogo />}
        className="container"
      >
        {combinedLeaderboards?.length > 0 ? (
          <ul className={styles.clasament_container}>
            {combinedLeaderboards.map((item, i) =>
              item.isLoading ? (
                <div key={i} className={styles.loadingContainer}>
                  {/* You can use your LoaderWithLogo component or any loading indicator */}
                  <LoaderWithLogo />
                </div>
              ) : (
                <li
                  key={i}
                  className={classNames(styles.clasament_item, {
                    [styles.first_places]: item.position <= 3,
                    [styles.custom_highlight]:
                    filterType === "overall" &&
                    (item.name === nickname || item.position === rank),
                    [styles.custom_highlight_team]:
                    filterType === "favoriteTeam" &&
                    (item.name === favoriteTeamUserProfile?.name ||
                      item.position === favoriteTeamUserProfile?.position),
                  })}
                >
                  {item.position === 1 ? (
                    <img
                      className={classNames(styles.icon, styles.first_place)}
                      src={firstPlaceIconWhite}
                      alt="1"
                    />
                  ) : null}
                  {item.position === 2 || item.position === 3 ? (
                    <img
                      className={styles.icon}
                      src={secondThirdPlaceIcon}
                      alt="2|3"
                    />
                  ) : null}
                  <span className={styles.position}>
                    {item.position === 0 ? "-" : item.position}
                  </span>
                  <span className={styles.name}>{t(item.name)}</span>
                  <span className={styles.points}>
                    {item.points !== null && typeof item.points === "number"
                      ? item.points.toFixed(2)
                      : "-"}{" "}
                    <span>{t("matches.pts")}</span>
                  </span>
                </li>
              )
            )}
          </ul>
        ) : (
          !isLoading && (
            <div className="no-results">
              <div className="text">
                <h2>{t("leaderboards.no_results")}</h2>
                <p>{t("Please come back later")}</p>
              </div>
            </div>
          )
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Leaderboards;
