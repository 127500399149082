import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Layout from "./components/Layout";
import api from "./api/axios";
import i18next from "i18next";
import { getParamValue } from "./utils/globalFunctions";
import { Register, Matches, Leaderboards, Prizes, Chat, IndividualChat } from "./pages";

const App = () => {
  const { access_token, newAccountSportWorld, email, nickname, userId } = useStoreState(
    (state) => state.user
  );
  const { login, getRefreshedToken, logout, setIsLoading, getLang } = useStoreActions(
    (actions) => actions.user
  );

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    api.setupApiInterceptors(getRefreshedToken, logout);
    setIsLoading(false);
  }, [getRefreshedToken, setIsLoading, logout]);

  useEffect(() => {
    if (!!getParamValue(params, "email")) {
      const fetchData = async () => {
        await login({
          email: getParamValue(params, "email"),
          lang: getParamValue(params, "lang") || "en",
        });
      };
      fetchData().catch(console.error);
    } else {
      getLang(getParamValue(params, "lang"));
    }
    i18next.changeLanguage(getParamValue(params, "lang"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadFreshchatWidget = () => {
      const script = document.createElement("script");
      script.src = "//eu.fw-cdn.com/10425374/297053.js";
      script.setAttribute("chat", "true");
      document.body.appendChild(script);

      script.onload = () => {
        const checkFreshchatLoaded = () => {
          if (typeof window.fcWidget !== "undefined") {
            window.fcWidget.on("widget:loaded", function () {
              window.fcWidget.setExternalId(userId);
              window.fcWidget.user.setFirstName(nickname);
              window.fcWidget.user.setEmail(email);
            });
          } else {
            // Reîncercăm la fiecare 500ms dacă widget-ul nu este încă disponibil
            setTimeout(checkFreshchatLoaded, 500);
          }
        };
        // Primul apel pentru verificare
        checkFreshchatLoaded();
      };

      // Cleanup pentru a elimina scriptul când componenta este demontată
      return () => {
        document.body.removeChild(script);
      };
    };

    if (access_token && !newAccountSportWorld) {
      if (location.pathname.includes("/chat/")) {
        if (typeof window.fcWidget !== "undefined") {
          window.fcWidget.hide();
        }
      } else {
        if (typeof window.fcWidget !== "undefined") {
          window.fcWidget.show();
        } else {
          loadFreshchatWidget();
        }
      }
    }

    return () => {
      if (typeof window.fcWidget !== "undefined") {
        window.fcWidget.hide();
      }
    };
  }, [access_token, newAccountSportWorld, email, nickname, userId, location.pathname]);

  return (
    <div className="App">
      {access_token && !newAccountSportWorld ? getMainRoutes() : getLoginRoutes()}
    </div>
  );
};

export default App;

const getMainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Matches />} />
        <Route path="leaderboards" element={<Leaderboards />} />
        <Route path="prizes" element={<Prizes />} />
        <Route path="chat" element={<Chat />} />
        <Route path="chat/:id" element={<IndividualChat />} />
      </Route>
    </Routes>
  );
};

const getLoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Register />} />
    </Routes>
  );
};
