import { action, thunk } from "easy-peasy";
import api from "../../api/axios";
import { AUTH } from "./apiRoutes";

const userModel = {
  userId: null,
  lang: null,
  isLoading: true,
  access_token: null,
  refresh_token: null,
  email: null,
  nickname: null,
  error: false,
  points: null,
  rank: null,
  profileImage: null,
  newAccountSportWorld: null,
  favoriteTeamSW: null,
  country: null,
  pointsSecond: null,
  rankSecond: null,

  setUserId: action((state, payload) => {
    state.userId = payload;
  }),
  setLang: action((state, payload) => {
    state.lang = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setAccessToken: action((state, access_token) => {
    state.access_token = access_token;
  }),
  setRefreshToken: action((state, refresh_token) => {
    state.refresh_token = refresh_token;
  }),
  setRefreshedToken: action((state, newAccessToken) => {
    state.access_token = newAccessToken;
  }),
  setEmail: action((state, email) => {
    state.email = email;
  }),
  setPoints: action((state, points) => {
    state.points = points;
  }),
  setRank: action((state, rank) => {
    state.rank = rank;
  }),
  setPointsSecond: action((state, points) => {
    state.pointsSecond = points;
  }),
  setRankSecond: action((state, rank) => {
    state.rankSecond = rank;
  }),
  setNickname: action((state, nickname) => {
    state.nickname = nickname;
  }),
  setNicknameError: action((state, val) => {
    state.error = val;
  }),
  setProfileImage: action((state, image) => {
    state.profileImage = image;
  }),
  setNewAccountSportWorld: action((state, newAccountSportWorld) => {
    state.newAccountSportWorld = newAccountSportWorld;
  }),
  setCountry: action((state, country) => {
    state.country = country;
  }),
  setFavoriteTeamSW: action((state, favoriteTeamSW) => {
    state.favoriteTeamSW = favoriteTeamSW;
  }),
  setToken: action((state, tokenPayload) => {
    if (tokenPayload) {
      const { accessToken, refreshToken } = tokenPayload;
      state.access_token = accessToken;
      state.refresh_token = refreshToken;
      api.defaults.headers.common["Authorization"] = `Bearer ${state.access_token}`;
    } else {
      state.access_token = null;
      state.refresh_token = null;
      state.isLoading = false;
    }
  }),

  login: thunk(async (actions, { email, nickname, lang }) => {
    const payload = { email, nickname };
    actions.setLang(lang);
    actions.setIsLoading(true);
    actions.setEmail(email);

    try {
      const { data } = await api.post(AUTH.AUTHENTICATION, payload);
      const { _id, nickname, profilePicture, newAccountSportWorld, country, favoriteTeamSW } = data;

      actions.setToken(data);
      actions.setUserId(_id);
      actions.setNickname(nickname);
      actions.setProfileImage(profilePicture);
      actions.setNewAccountSportWorld(newAccountSportWorld);
      actions.setCountry(country);
      actions.setFavoriteTeamSW(data?.favoriteTeamSW);
    } catch (error) {
      console.error(error);
    }
    actions.setIsLoading(false);
  }),

  getLang: thunk(async (actions, lang) => {
    actions.setLang(lang);
  }),

  getUserStats: thunk(async (actions, chalangeId) => {
    try {
      const { data } = await api.get(AUTH.POINTS_AND_RANK + "?challengeId=" + chalangeId);
      if (data) {
        actions.setPoints(data?.allTimeUserRanking?.points?.toFixed(2) || 0);
        actions.setRank(data?.allTimeUserRanking?.position);
      }
    } catch (error) {
      console.error(error);
    }
  }),

  getUserSecondStats: thunk(async (actions, { chalangeId, type, startDate, endDate }) => {
    try {
      let url = `${AUTH.POINTS_AND_RANK}?challengeId=${chalangeId}&type=${type}`;

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      const { data } = await api.get(url);

      if (data && data.allTimeUserRanking) {
        const points = data.allTimeUserRanking.points;
        const position = data.allTimeUserRanking.position;
        actions.setPointsSecond(points !== undefined && points !== null ? parseFloat(points.toFixed(2)) : null);
        actions.setRankSecond(position !== undefined && position !== null ? position : null);
      } else {
        actions.setPointsSecond(null);
        actions.setRankSecond(null);
      }
    } catch (error) {
      console.error(error);
      actions.setPointsSecond(null);
      actions.setRankSecond(null);
    }
  }),


  register: thunk(async (actions, { nickname, teamId, image, emailNotification }) => {
    actions.setIsLoading(true);

    try {
      const { data } = await api.patch(AUTH.REGISTER, {
        nickname: nickname,
        teamId: teamId,
        profilePicture: image,
        emailNotification,
      });
      if (data) {
        actions.setUserId(data?._id);
        actions.setNickname(nickname);
        actions.setProfileImage(image);
        actions.setNicknameError(false);
        actions.setNewAccountSportWorld(false);
        actions.setFavoriteTeamSW(data?.favoriteTeamSW);
        actions.setCountry(data?.country);
      }
    } catch (error) {
      actions.setNicknameError(true);
      console.error(error);
    }

    actions.setIsLoading(false);
  }),

  getRefreshedToken: thunk(async (actions, skip, { getState }) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${getState().refresh_token}`;
      const { data } = await api.post(AUTH.REFRESH_TOKEN);

      actions.setRefreshedToken(data.accessToken);
      return data.accessToken;
    } catch (error) {
      console.error(error);
    }
  }),
};

export default userModel;
